var $ = require("jquery")


var NOWTIME = new Date().getTime()

function buildTimeline(parentEl, start, end, tipClass) {
  var el             = parentEl.find('.timeline')
    , elProgress     = parentEl.find('.timeline__progress')
    , elStart        = parentEl.find('.timeline__start .timeline-date')
    , elEnd          = parentEl.find('.timeline__end .timeline-date')

    , fromStartPrecise = (NOWTIME - start) / (1000 * 60 * 60 * 24)
    , tilEndPrecise    = (end - NOWTIME) / (1000 * 60 * 60 * 24)
    , fromStart        = Math.round(fromStartPrecise)
    , tilEnd           = Math.round(tilEndPrecise)

    , startDate = new Date(start)
    , endDate = new Date(end)

    , monthNames = [
      "Jan.", "Feb.", "Mar.",
      "Apr.", "May", "Jun.", "Jul.",
      "Aug.", "Sept.", "Oct.",
      "Nov.", "Dec."
    ]

    , startContent   = 'Starts ' + monthNames[startDate.getMonth()] + ' ' + startDate.getDate()
    , endContent     = 'Ends ' + monthNames[endDate.getMonth()] + ' ' + endDate.getDate()

    , gvwyLength     = end - start
    , gvwyPassed     = NOWTIME - start
    , gvwyPercentage = gvwyPassed / gvwyLength


    if (fromStartPrecise < 0) {
      if (fromStartPrecise > -1) {
        startContent = 'Starting soon!'
      } else {
        startContent = 'Starts in ' + fromStart * -1 + (fromStart == -1 ? ' day' : ' days')
      }
    } else if (0 < fromStartPrecise < 1) {
      startContent = 'Started today'
    }

    if (tilEndPrecise < 1) {
      var hours   = Math.floor((end - NOWTIME) / (1000 * 60 * 60))
        , minutes = Math.floor((end - NOWTIME) / (1000 * 60))

      if (hours > 0) {
        endContent = 'Ending in ' + hours + ' hour' + (hours > 1 ? 's ' : ' ')
      } else if (hours < 0 && minutes > 0 ){
        endContent = 'Better hurry! Ending in '+ minutes +' minute' + (minutes > 1 ? 's ' : ' ')
      } else {
        endContent = 'This giveaway is over!'
      }
    }

  // Update Tooltip Data
  elStart.html(startContent)
  elEnd.html(endContent)

  // Update Tooltip Classes
  tipClass = tipClass || ''
  elStart.addClass(tipClass)
  elEnd.addClass(tipClass)

  gvwyPercentage = gvwyPercentage >= 1 ? 1 : gvwyPercentage


  // Animate Progress Bar
  el.addClass('timeline--rendered')
  el.addClass(gvwyPercentage >= 1 ? 'timeline--complete' : '')

  if(gvwyPercentage < 1) {
    elProgress.css(
      {"width": gvwyPercentage * 100 + "%",
       "transition": "width " + gvwyPercentage + "s ease-in"})
  } else {
    elProgress.css(
      {"width": "calc(100% - 4px)",
       "transition": "width " + gvwyPercentage + "s ease-in"})
  }
}

function gvwyLength(start, end) {
  var length = (end - start) / (1000 * 60 * 60 * 24)

  return length;
}

function getGvwy(rid) {
  cptr.load_raffle(rid, function (err, raffle){
    var start = raffle.info.timeline.start
      , end = raffle.info.timeline.end
      , length = gvwyLength(start, end)
      , parent = $('.hero__timeline')

      buildTimeline(parent, start, end, 'top')
  })
}

getGvwy('1d6dge2ykdw0r')

$('.btn--form').on('click', function(e){
  e.preventDefault()
  $('.page').addClass('page--modal')
})

$('.modal__close').on('click', function() {
  $('.page').removeClass('page--modal')
})
